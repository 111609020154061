<script lang="ts">
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";
	import type { ProductGroupWithTextifiedContent } from "../../../core/schema/ProductGroup.js";
	import DynamicPicture from "./Picture/DynamicPicture.svelte";
	import { getProductGroupPath } from "../../../core/schema/paths/getProductGroupPath.js";
	import type { ProductWithTextifiedContent } from "../../../core/schema/Product.js";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./ProductGroupCard.translations.json";

	export let group: ProductGroupWithTextifiedContent;
	export let product: ProductWithTextifiedContent | undefined = undefined;
	export let index: number;

	const translate = getTranslate(translations);

	let link: string | undefined;
	let target: "_blank" | "_self" = "_self";
	$: {
		if (product?.externalUrl) {
			link = product.externalUrl;
			target = "_blank";
		} else {
			link = `/${getProductGroupPath(group)}`;
			target = "_self";
		}
	}
</script>

<a
	role={link ? "link" : "none"}
	href={link}
	{target}
	use:flyInOnScroll
	class="hover:bg-tertiary-300 px-[1.375rem] lg:pl-[3.75rem] lg:pr-[1.375rem]"
>
	<div
		class="border-tertiary-300 flex flex-row items-center gap-4 border-b py-7 md:gap-[3.188rem] md:py-16 lg:items-start"
	>
		<span class="md:text-2md hidden text-[1.188rem] text-black opacity-30 sm:block">
			{nbspify(index.toString().padStart(2, "0"))}
		</span>
		<DynamicPicture
			image={(product ?? group).primaryImage}
			alt="{translate('imageFor')} {(product ?? group).name}"
			width={177}
			height={165}
			class="h-[6.063rem] min-w-[6.5rem] max-w-[6.5rem] md:h-[10.313rem] md:min-w-[11.063rem]"
			imgClass="h-full w-full object-contain"
			bucket="storage"
		/>
		<div class="flex flex-col items-center justify-between gap-8 lg:w-full lg:flex-row">
			<p class="md:text-2lg text-2md sm:text-lg lg:max-w-[30rem] xl:max-w-[44rem]">
				{nbspify((product ?? group).name)}
			</p>
			<p
				class="lg:text-md xl:text-2md hidden w-full max-w-[34rem] text-left text-xs font-light text-black opacity-30 lg:inline xl:max-w-[50rem]"
			>
				{nbspify((product ?? group).textifiedContent)}
			</p>
		</div>
	</div>
</a>
